import React from "react"
import styled from "styled-components"

export default function ProjectBox({ img, title, subtitle, text, action }) {
  return (
    <Wrapper>
      <ImgBtn
        className="aniamte pointer"
        onClick={action ? () => action() : null}
      >
        <img src={img} alt="Arquivo"></img>
      </ImgBtn>
      <h3 className="font10 extraBold">{title}</h3>
      <p className="font10">{text}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 0px;
  }
`
const ImgBtn = styled.button`
  width: 40px;
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`
