/* eslint-disable import/no-anonymous-default-export */

export default {
  MuiMobileStepper: {
    styleOverrides: {
      dotActive: {
        backgroundColor: "#ffb335",
      },
      dot: {
        backgroundColor: "gray",
      },
    },
  },
}
