import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import CardArticle from "components/CardArticle"
import ValorEconomicoLogo from "assets/ValorEconomico.png"
import CRCLogo from "assets/CRC.jpg"
import ExameLogo from "assets/exame-logo.jpg"
import ExameInvestLogo from "assets/exame-invest.png"
import MoneyTimesLogo from "assets/moneytimes-logo.png"
import LogoB3 from "assets/LogoB3Decod.png"
import IconButton from "@mui/material/IconButton"
import CircleIcon from "@mui/icons-material/Circle"

export default function StepperArticles() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const CustomDots = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <IconButton component="label" onClick={() => onClick()}>
        <CircleIcon className={active ? "DotActive" : "DotInactive"} />
      </IconButton>

      // <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
      //   {index + 1}
      // </li>
    )
  }

  return (
    <Carousel
      responsive={responsive}
      containerClass="container"
      showDots
      arrows={false}
      customDot={<CustomDots />}
    >
      <CardArticle
        image={LogoB3}
        title="Lev celebra lançamento de distribuidora na B3"
        resource_name="B3"
        date="09.02.2023"
        url="https://www.b3.com.br/pt_br/noticias/lev-celebra-lancamento-de-distribuidora-na-b3.htm"
      />
      <CardArticle
        image={CRCLogo}
        title="Com foco em clientes institucionais, Lev será lançada no próximo dia 9, na B3"
        resource_name="CRC NEWS"
        date="31.01.2023"
        url="https://crcnews.com.br/corporativo/com-foco-em-clientes-institucionais-lev-sera-lancada-no-proximo-dia-9-na-b3/"
      />
      <CardArticle
        image={ExameInvestLogo}
        title="Sócios da Inv lançam corretora voltada para fundos e miram fatia de 5% de mercado futuro"
        resource_name="EXAME INVEST"
        date="30.01.2023"
        url="https://exame.com/invest/mercados/socios-da-inv-lancam-corretora-voltada-para-fundos-e-miram-fatia-de-5-de-mercado-futuro/"
      />
      <CardArticle
        image={ValorEconomicoLogo}
        title="B3 autoriza início das operações da Lev DTVM"
        resource_name="VALOR ECONÔMICO"
        date="09.01.2023"
        url="https://valor.globo.com/financas/noticia/2023/01/09/284e4dcc-destaques.ghtml"
      />
      <CardArticle
        image={MoneyTimesLogo}
        title="Radar de Negócios: 23 empresas para ficar de olho em 2023"
        resource_name="MONEY TIMES"
        date="04.01.2023"
        url="https://www.moneytimes.com.br/radar-negocios-23-empresas-startups-fique-de-olho-2023/"
      />
      <CardArticle
        image={ExameLogo}
        title="Dez startups que estão com quase 100 vagas de emprego; há posições em home office e trabalho híbrido."
        resource_name="EXAME"
        date="23.09.2022"
        url="https://exame.com/carreira/dez-startups-que-estao-com-quase-100-vagas-de-emprego-ha-posicoes-em-home-office-e-trabalho-hibrido/"
      />
      <CardArticle
        image={CRCLogo}
        title="Marcelo Cerize cria corretora para levar menos custos e mais eficiência a gestores."
        resource_name="CRC NEWS"
        date="28.08.2022"
        url="https://crcnews.com.br/edicoes-revista/edicao-49-nosso-primeiro-pilar-e-a-resiliencia-operacional-pois-a-gestora-nao-pode-parar/28/08/2022/"
      />
      <CardArticle
        image={ValorEconomicoLogo}
        title="LEV, dos irmãos Cerize, começa com investidor institucional, mas tem varejo no radar."
        resource_name="VALOR ECONÔMICO"
        date="26.08.2022"
        url="https://valor.globo.com/financas/noticia/2022/08/26/lev-dos-irmaos-cerize-comeca-com-investidor-institucional-mas-tem-varejo-no-radar.ghtml"
      />

      <CardArticle
        image={ValorEconomicoLogo}
        title="Novas corretoras buscam nichos em mercado ocupado por XP e BTG."
        resource_name="VALOR ECONÔMICO"
        date="06.06.2022"
        url="https://valor.globo.com/financas/noticia/2022/06/06/novas-corretoras-buscam-nichos-de-mercado-para-ocupar.ghtml"
      />
    </Carousel>
  )
}
