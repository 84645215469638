import React from "react"
import { Link } from "react-scroll"
import LogoLevWhite from "assets/Lev-White.png"
import { Link as LinkDOM } from "react-router-dom"
import { Grid, Typography, Button } from "@mui/material"
import SimpleDialog from "components/SimpleDialog"
import Divider from "@mui/material/Divider"
import BancoCentralPNG from "assets/banco-central.png"
import BSMPNG from "assets/bsm.png"
import B3PNG from "assets/b3.png"
import B3SELOPNG from "assets/b3_selo.png"
import CMVPNG from "assets/cvm.png"

import "./styles.scss"

export default function Footer() {
  return (
    <>
    <div style={{ minHeight: "2vh" }} />
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="flex-start"
        className=" InnerWrapper"
        spacing={2}
        sx={{ padding: "0 50px 20px 50px", backgroundColor: "#0055a6"}}
      >

        <Grid item md={1} xs={12}>
          <Link to="home" smooth={true} offset={-80}>
            <img
              src={LogoLevWhite}
              alt="Lev"
              style={{ width: "120px", padding: "1vh" }}
            />
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className="FooterBackground InnerWrapper"
        spacing={2}
        pt={2}
        gap="12px"
      >
        <Grid item xs={12} md={2}>
          <Typography variant="bodyWhite" component="div" fontSize={14}>
            <b>
              LEV DISTRIBUIDORA DE TITULOS <br />
              E VALORES MOBILIARIOS LTDA. <br />
            </b>
          </Typography>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={13}
            sx={{ marginTop: "12px" }}
          >
            CNPJ n° 45.457.891/0001-48 <br />
            Av. Faria Lima, n° 1234, 5° Andar, Jd. Paulistano <br />
            CEP 01451-001 • São Paulo • SP
            <br /> <br />
            Termos de uso e privacidade
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="bodyWhite" component="div" fontSize={14}>
            <b>OUVIDORIA</b>
          </Typography>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={13}
            sx={{ marginTop: "12px" }}
          >
            <a style={{ color: "white" }} href="tel:0800-5915441">
              0800-5915441
            </a>
            <br />
            Seg. à Sex. – 10:00 às 18:00 (Dias úteis)
            <br />
            <br />
            <a style={{ color: "white" }} href="mailto:ouvidoria@lev.com.vc">
              ouvidoria@lev.com.vc
            </a>
            <br />
            <br />
            Correspondência física para o endereço: Ouvidoria - Av. Brigadeiro
            Faria Lima, 1.234, 5º andar, CEP: 01451-001. São Paulo - SP
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="bodyWhite" component="div" fontSize={14}>
            <b>COMERCIAL</b>
          </Typography>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={13}
            sx={{ marginTop: "12px" }}
          >
            <a style={{ color: "white" }} href="tel:+551140406610">
              11 4040-6610
            </a>
          </Typography>
          <Typography variant="bodyWhite" component="div" fontSize={13}>
            <a style={{ color: "white" }} href="mailto:comercial@lev.com.vc">
              comercial@lev.com.vc
            </a>
          </Typography>
          <br />
          <Typography variant="bodyWhite" component="div" fontSize={14}>
            <b>FALE CONOSCO</b>
          </Typography>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={13}
            sx={{ marginTop: "12px" }}
          >
            <a style={{ color: "white" }} href="tel:+551140406610">
              11 4040-6610
            </a>
          </Typography>
          <div>
            <Button
              onClick={() =>
                window.open(
                  "https://www.bsmsupervisao.com.br/ressarcimento-de-prejuizos/como-funciona",
                  "_blank"
                )
              }
              style={{
                padding: 0,
                marginTop: "10px",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              <b>Atendimento MRP</b>
            </Button>
          </div>
          <SimpleDialog />
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="bodyWhite" component="div" fontSize={14}>
            <b>CANAL DE DENÚNCIAS</b>
          </Typography>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={13}
            sx={{ marginTop: "12px" }}
          >
            <a
              href="https://www.finaudtec.com.br/Facti/?Key=EFB800EBECE3239E82BDF0BA4E4ACBDF"
              className="StyleP"
              style={{ color: "white" }}
            >
              Este é um canal de atendimento exclusivo da LEV DTVM para
              comunicação segura, ou se preferir anônima, de posturas que
              violem o código de conduta e boas práticas da instituição ou da
              legislação vigente.
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={14}
            sx={{ marginBottom: "12px" }}
          >
            <b>COMPLIANCE</b>
          </Typography>
          <nav>
            <LinkDOM
              to="/documentos"
              style={{ color: "white", fontSize: "13px" }}
            >
              Regras
            </LinkDOM>
          </nav>
          <nav>
            <LinkDOM
              to="/documentos"
              style={{ color: "white", fontSize: "13px" }}
            >
              Políticas Internas
            </LinkDOM>
          </nav>

          <nav>
            <LinkDOM
              to="/documentos"
              style={{ color: "white", fontSize: "13px" }}
            >
              Contratos
            </LinkDOM>
          </nav>

          <nav>
            <LinkDOM
              to="/documentos"
              style={{ color: "white", fontSize: "13px" }}
            >
              Relatórios
            </LinkDOM>
          </nav>
        </Grid>
        <Grid item xs={12}>
          <Divider color="#FFF" />
        </Grid>
        <Grid item md={12}>
          <Typography
            variant="bodyWhite"
            component="div"
            fontSize={13}
            sx={{ textAlign: "justify" }}
          >
            {`A Lev DTVM, inscrita no CNPJ sob o nº 45.457.891/0001-48, é uma instituição financeira autorizada a funcionar pelo Banco Central do Brasil e exime-se da responsabilidade por danos sofridos aos seus clientes, por falha de serviços disponibilizados por terceiros. Ainda assim, destaca que toda transmissão de ordem por meio digital está sujeita a interrupções ou atrasos, podendo impedir ou prejudicar o envio de ordens ou a recepção de informações atualizadas. A Lev destaca que os recursos de seus clientes são mantidos em conta de registro utilizada exclusivamente pela DTVM para registro de operações de cada cliente, conforme previsto no § 6º, Art. 12 da Resolução CMN nº 5.008, de 24 de março 2022. Não obstante, a Lev DTVM informa que as contas de recursos não se confundem com as contas de pagamento de que tratam os artigos. 6º, inciso IV, e 12 da Lei nº 12.865, de 9 de outubro de 2013. Por essa razão, esclarece que os recursos mantidos em contas de registro não possuem regime jurídico equivalente ao dos recursos mantidos em conta de pagamento, nos termos previstos no art. 12 da Lei nº 12.865, de 2013.`}
          </Typography>
        </Grid>
      </Grid>
      <div class="logos-rodape">
        <div class="container">
          <a
            href="https://www.bcb.gov.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={BancoCentralPNG} alt="" />
          </a>
          <a
            href="https://www.bsmsupervisao.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={BSMPNG} alt="" />
          </a>
          <a
            href="https://www.b3.com.br/pt_br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={B3PNG} alt="" />
          </a>
          <a
            href="https://www.b3.com.br/pt_br/b3/qualificacao-e-governanca/certificacoes/selos-pqo/corretoras-certificadas.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={B3SELOPNG} alt="" />
          </a>
          <a
            href="https://www.gov.br/cvm/pt-br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={CMVPNG} alt="" />
          </a>
        </div>
      </div>
      <div className="FooterBorder" />
    </>
  )
}
