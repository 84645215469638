import { Box, Grid, Typography } from "@mui/material"
import "./styles.scss"
import VerticalLines from "assets/vertical-lines.png"

export default function Faq() {
  return (
    <>
      <section id="faq">
        <Box className="FaqBackground">
          <img
            src={VerticalLines}
            alt="VerticalLines"
            className="FaqVerticalLines"
          />
          <div className="FaqTitle">
            <div className="FaqTitleText">Perguntas Frequentes</div>
            <div className="FaqTitleLine" />
          </div>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={5}
            style={{ padding: "10vh" }}
          >
            <Grid item xs={10} md={4}>
              <div style={{ minHeight: 260 }}>
                <Typography variant="BodySubTitle" color="#ffb335">
                  A Lev atende pessoa física?
                </Typography>
                <Typography variant="bodyBlue" component="div" color="white">
                  A Lev foi desenvolvida para atender principalmente nossos
                  clientes institucionais, entretanto está em nossos planos
                  atender pessoas físicas oferecendo as mesmas condições de
                  investimento disponibilizada aos grandes investidores.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={10} md={4}>
              <div
                style={{ minHeight: 260, position: "relative", zIndex: "1" }}
              >
                <Typography variant="BodySubTitle" color="#ffb335">
                  Como abrir uma conta na Lev?
                </Typography>
                <Typography variant="bodyBlue" component="div" color="white">
                  Se você quer fazer parte do nosso espaço de investimento para
                  investidores institucionais{" "}
                  <b>
                    <a
                      style={{ color: "#ffb335" }}
                      href="mailto:dl-cadastro@lev.com.vc?subject=Abertura de conta"
                    >
                      CLIQUE AQUI
                    </a>
                  </b>{" "}
                  e inicie agora mesmo o seu cadastro.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={10} md={4}>
              <div style={{ minHeight: 260 }}>
                <Typography variant="BodySubTitle" color="#ffb335">
                  A Lev cobra taxas?
                </Typography>
                <Typography variant="bodyBlue" component="div" color="white">
                  A Lev não opera como contraparte, porque nosso objetivo maior
                  é oferecer excelência na execução de ordens, atendimento e
                  infraestrutura para nossos clientes. Para mantermos esse
                  ambiente de forma sustentável cobramos taxas de intermediação
                  adequadas ao perfil de cada cliente.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={10} md={4}>
              <div style={{ minHeight: 260 }}>
                <Typography variant="BodySubTitle" color="#ffb335">
                  Como funciona a execução?
                </Typography>
                <Typography variant="bodyBlue" component="div" color="white">
                  Desenvolvemos soluções diferenciadas com foco em execução
                  eletrônica aumentando o grau de competitividade de nossos
                  clientes. Adicionalmente, possuímos uma mesa de operações com
                  profissionais experientes para atender demandas daqueles que
                  optam por esse tipo de execução.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}
