import { Box, Grid, Typography } from "@mui/material"
import "./styles.scss"
import VerticalLines from "assets/vertical-lines.png"

import FIAGRO_PROSPECTO from "assets/pdf/FIAGRO_NCH_RECEBIVEIS_3-EMISSAO_PROSPECTO.pdf"
import FIAGRO_LAMINA from "assets/pdf/NCH Recebiveis - 3° Emissão - Lâmina 15.08.2023 - v004.pdf"
import MATERIAL_NCRA11 from "assets/pdf/Material Publicitário NCRA11 - 3° Emissão.pdf"
import ENCERRAMENTO from "assets/pdf/ENCERRAMENTO.pdf"
import ANUNCIO from "assets/pdf/ANUNCIO_DE_INICIO_15.08.2023-v005.pdf"

const pdfClick = (pdfFile) => window.open(pdfFile)

export default function Fiagro() {
  return (
    <>
      <section id="fiagro">
        <Box className="FaqBackground">
          <img
            src={VerticalLines}
            alt="VerticalLines"
            className="FaqVerticalLines"
          />
          <div className="FaqTitle">
            <div className="FaqTitleText">Investimentos - Oferta Pública</div>
            <div className="FaqTitleLine" />
          </div>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={5}
            style={{ padding: "10vh" }}
          >
            <Grid item xs={10} md={4}>
              <div style={{ minHeight: 260 }}>
                <Typography variant="BodySubTitle2" color="#ffb335">
                Fiagro – Encerrada
                </Typography>
                <Typography variant="bodyBlue" component="div" color="white">
                NCRA11 – NCH Recebíveis do Agronegócio Fiagro Imobiliário – 3ª Emissão
                Público-alvo: Investidores Qualificados e Profissionais   
                </Typography>
                <div style={{ minHeight: 50, marginTop: 30 }}>
                <Typography variant="bodyBlue" component="div" color="white">
                Documentos:
                </Typography>
                </div>

                <div style={{ margin: 10 }}>
                <Typography variant="bodyBlue" component="div" color="white">
                <b>
                    <a
                      style={{ color: "white" }}
                      onClick={() => pdfClick(ANUNCIO)}
                      href="#"
                    >
                      Anúncio de Início
                    </a>
                  </b>{" "}
                </Typography>
                </div>

                <div style={{ margin: 10 }}>
                <Typography variant="bodyBlue" component="div" color="white">
                <b>
                    <a
                      style={{ color: "white" }}
                      onClick={() => pdfClick(FIAGRO_LAMINA)}
                      href="#"
                    >
                      Lâmina
                    </a>
                  </b>{" "}
                
                </Typography>
                </div>

                <div style={{ margin: 10 }}>
                <Typography variant="bodyBlue" component="div" color="white">
                <b>
                    <a
                      style={{ color: "white" }}
                      onClick={() => pdfClick(FIAGRO_PROSPECTO)}
                      href="#"
                    >
                      Prospecto
                    </a>
                  </b>{" "}
                
                </Typography>
                </div>
                
                <div style={{ margin: 10 }}>
                <Typography variant="bodyBlue" component="div" color="white">
                <b>
                    <a
                      style={{ color: "white" }}
                      onClick={() => pdfClick(MATERIAL_NCRA11)}
                      href="#"
                    >
                      Material Publicitário
                    </a>
                  </b>{" "}
                
                </Typography>
                </div>

                <div style={{ margin: 10 }}>
                <Typography variant="bodyBlue" component="div" color="white">
                <b>
                    <a
                      style={{ color: "white" }}
                      onClick={() => pdfClick(ENCERRAMENTO)}
                      href="#"
                    >
                      Anúncio de Encerramento
                    </a>
                  </b>{" "}
                
                </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={10} md={4}>
              <div
                style={{ minHeight: 260, position: "relative", zIndex: "1" }}
              >
                <Typography variant="BodySubTitle2" color="#ffb335">
                Leia o prospecto antes de aceitar a oferta e em especial a seção dos fatores de risco
                </Typography>
                
              </div>
            </Grid>

            
          </Grid>
        </Box>
      </section>
    </>
  )
}
