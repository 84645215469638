/* eslint-disable import/no-anonymous-default-export */
const white = "#fbfdff"
const blue = "#0055a6"
const yellow = "#ffb335"

export default {
  BodyTitle: {
    color: blue,
    fontFamily: "Segoe UI",
    letterSpacing: "0.25em",
    fontWeight: 300,
    fontSize: 30,
  },
  BodyTitle2: {
    color: yellow,
    fontFamily: "Segoe UI",
    letterSpacing: "0.25em",
    fontWeight: 500,
    fontSize: 20,
  },
  BodySubTitle: {
    color: blue,
    fontFamily: "Segoe UI",
    fontWeight: 300,
    fontSize: 30,
  },
  BodySubTitle2: {
    color: blue,
    fontFamily: "Segoe UI",
    fontWeight: 750,
    fontSize: 30,
  },
  h4: {
    color: white,
    fontFamily: "Segoe UI",
    fontWeight: 500,
    letterSpacing: "0.3em",
    fontSize: 50,
  },
  h5: {
    color: white,
    fontFamily: "Segoe UI",
    fontWeight: 500,
    letterSpacing: "0.3em",
    fontSize: 22,
  },
  body: {
    color: white,
    fontFamily: "Segoe UI",
    fontWeight: 200,
    fontSize: 20,
  },
  bodyWhite: {
    color: white,
    fontFamily: "Segoe UI",
    fontWeight: 400,
    fontSize: 20,
  },
  bodyBlue: {
    color: blue,
    fontFamily: "Segoe UI",
    fontWeight: 600,
    fontSize: 20,
  },
}
