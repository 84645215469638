import React from "react"
import ReactDOM from "react-dom/client"
import TagManager from "react-gtm-module"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./style/flexboxgrid.min.css"
import "./style/index.css"
import App from "./App"

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GA_TRACKING_CODE,
}

TagManager.initialize(tagManagerArgs)
const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
