import React from "react"
import styled from "styled-components"
import "./styles.scss"

export default function PeopleBox({ img, title, subtitle, text, action }) {
  return (
    <Wrapper>
      <ImgBtn
        className="animate pointer"
        onClick={action ? () => action() : null}
      >
        <img
          src={img}
          alt="project"
          style={{ borderRadius: "50%", height: "120px" }}
        />
      </ImgBtn>
      <h3 className="font20 extraBold" translate="no">
        {title}
      </h3>
      <h3 className="font20 extraBold">{subtitle}</h3>
      <p className="PeopleText">{text}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 0px;
  }
`
const ImgBtn = styled.button`
  border-radius: 50%;
  width: 120px;
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`
