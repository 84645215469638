import React from "react"
import { createBrowserHistory } from "history"
import theme from "theme"

import { ThemeProvider } from "@mui/material/styles"

import AllRoutes from "router/Routes"

import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"

export default function App() {
  const history = createBrowserHistory({ window })

  return (
    <>
      <ThemeProvider theme={theme}>
        <HistoryRouter history={history}>
          <AllRoutes />
        </HistoryRouter>
      </ThemeProvider>
    </>
  )
}
