import styled from "styled-components"
// Components
import { Button } from "@mui/material"
import PDFLogo from "assets/pdf-icon.png"
import FileBox from "components/FileBox"
import { Link as LinkDOM } from "react-router-dom"
import RPA from "assets/pdf/LEV DTVM - Regras e Parâmetros de Atuação.pdf"
import PraticasSeguranca from "assets/pdf/Orientação Sobre Práticas de Segurança.pdf"
import PoliticaOuvidoria from "assets/pdf/LEV DTVM - Política de Ouvidoria.pdf"
import PoliticaAmbiental from "assets/pdf/Política Resposabilidade Socioambiental.pdf"
import PoliticaLavagem from "assets/pdf/Política de KYC e AML.pdf"
import PoliticaControlesInternos from "assets/pdf/Controles Internos.pdf"
import ContratoCustodia from "assets/pdf/LEV DTVM - Contrato de Intermediação.pdf"
import ManualRisco from "assets/pdf/Manual de Risco.pdf"
import PoliticaRisco from "assets/pdf/LEV DTVM Política de Risco.pdf"

import PoliticaGir from "assets/pdf/LEV DTVM Política de GIR - V2.pdf"
import Pilar3 from "assets/pdf/LEV DTVM Relatório de Pilar 3.pdf"
import CodigoEticaConduta from "assets/pdf/LEV DTVM Código de Ética e Conduta.pdf"
import PolíticaSegurança from "assets/pdf/LEV DTVM Política de Segurança da Informação e Cibernática.pdf"
import PolíticadeCompliance from "assets/pdf/Política de Compliance.pdf"
import CanaldeDenuncias from "assets/pdf/Regulamento do Canal de Denúncias.pdf"
import RelatorioOuvidoria1Semestre2023 from "assets/pdf/LEV DTVM - Relatório da Ouvidoria 1° Semestre 2023.pdf"
import RelatorioOuvidoria2Semestre2022 from "assets/pdf/LEV DTVM - Relatório da Ouvidoria 2° Semestre 2022.pdf"
import DemonstrativoFinanceiro1Semestre2023 from "assets/pdf/Lev DTVM - Demonstrações Financeiras 1º Semestre de 2023.pdf"
import DemonstrativoFinanceiro2022 from "assets/pdf/Lev DTVM - Demonstrações Financeiras 2º Semestre de 2022.pdf"
import GRSAC2022 from "assets/pdf/LEV DTVM Relatório GRSCA - 2022.pdf"


const pdfClick = (pdfFile) => window.open(pdfFile)

export default function Files() {
  return (
    <>
      <Wrapper>
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <LinkDOM to="/">
                <Button
                  variant="contained"
                  style={{
                    marginTop: "50px",
                    backgroundColor: "#0055A6",
                    color: "white",
                  }}
                >
                  VOLTAR PARA PÁGINA INICIAL
                </Button>
              </LinkDOM>
              <h1 className="font30 extraBold" style={{ paddingTop: "50px" }}>
                Regras
              </h1>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Regras e Parâmetros de Atuação"
                  action={() => pdfClick(RPA)}
                />
              </div>
            </div>
            <HeaderInfo>
              <h1 className="font30 extraBold" style={{ paddingTop: "50px" }}>
                Boas Práticas e Orientações
              </h1>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Orientação Sobre Práticas de Segurança"
                  action={() => pdfClick(PraticasSeguranca)}
                />
              </div>
            </div>
            <HeaderInfo>
              <h1 className="font30 extraBold" style={{ paddingTop: "50px" }}>
                Políticas Internas
              </h1>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de GIR"
                  action={() => pdfClick(PoliticaGir)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Relátorio de Pilar 3"
                  action={() => pdfClick(Pilar3)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Código de Ética e Conduta"
                  action={() => pdfClick(CodigoEticaConduta)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de Segurança Cibernética e da Informação"
                  action={() => pdfClick(PolíticaSegurança)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de Compliance"
                  action={() => pdfClick(PolíticadeCompliance)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Regulamento do Canal de Denúncias"
                  action={() => pdfClick(CanaldeDenuncias)}
                />
              </div>

              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de Ouvidoria"
                  action={() => pdfClick(PoliticaOuvidoria)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de Prevenção a Lavagem de Dinheiro e Conheça seu Cliente"
                  action={() => pdfClick(PoliticaLavagem)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Responsabilidade Socioambiental"
                  action={() => pdfClick(PoliticaAmbiental)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de Controles Internos"
                  action={() => pdfClick(PoliticaControlesInternos)}
                />
              </div>
            </div>

            <HeaderInfo>
              <h1 className="font30 extraBold" style={{ paddingTop: "50px" }}>
                Contratos
              </h1>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Contrato de Intermediação"
                  action={() => pdfClick(ContratoCustodia)}
                />
              </div>
            </div>

            <HeaderInfo>
              <h1 className="font30 extraBold" style={{ paddingTop: "50px" }}>
                Risco
              </h1>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Política de Risco"
                  action={() => pdfClick(PoliticaRisco)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Manual de Risco"
                  action={() => pdfClick(ManualRisco)}
                />
              </div>
            </div>

            <HeaderInfo>
              <h1 className="font30 extraBold" style={{ paddingTop: "50px" }}>
                Relatórios
              </h1>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Relatório da Ouvidoria 1° Semestre 2023"
                  action={() => pdfClick(RelatorioOuvidoria1Semestre2023)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Relatório da Ouvidoria 2° Semestre 2022"
                  action={() => pdfClick(RelatorioOuvidoria2Semestre2022)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Demonstrações Financeiras 2º Semestre de 2022"
                  action={() => pdfClick(DemonstrativoFinanceiro2022)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Demonstrações Financeiras 1º Semestre de 2023"
                  action={() => pdfClick(DemonstrativoFinanceiro1Semestre2023)}
                />
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <FileBox
                  img={PDFLogo}
                  title="Relatório GRSAC - 2022"
                  action={() => pdfClick(GRSAC2022)}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "10vh" }}></div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  width: 100%;
`
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`
