import React from "react"
import { Typography, Box, Grid } from "@mui/material"
import VerticalLines from "assets/vertical-lines.png"
import "./styles.scss"

export default function Mission() {
  return (
    <>
      <section id="mission">
        <Box className="MissionBackground">
          <img
            src={VerticalLines}
            alt="VerticalLines"
            className="MissionVerticalLines"
          />
          <div className="MissionTitle">
            <div className="MissionTitleText">Missão, visão e valores</div>
            <div className="MissionTitleLine" />
          </div>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={3}
              style={{ width: "50%", marginTop: "2vh" }}
            >
              <Typography variant="BodySubTitle" fontSize={25}>
                Missão, Visão e Valores são a base de qualquer cultura
                organizacional, com o passar do tempo, os conceitos foram
                incorporados ao dia a dia das organizações de todos os portes e
                atualmente, se tornaram decisivos para o engajamento dos
                empregados e líderes, elaboração de planejamento estratégico e
                alinhamento de comportamentos de atitudes da rotina e tomada de
                decisão da empresa.
              </Typography>
            </Grid>

            <Grid item style={{ margin: "8vh 10vh 10vh 10vh" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
              >
                <Grid item xs={12} md={4}>
                  <div className="MissionCardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Missão
                    </Typography>
                    <Typography
                      variant="bodyWhite"
                      component="div"
                      className="MissionCardText"
                    >
                      <br />
                      Simplificar o acesso ao mercado para investidores e
                      indústria financeira.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} md={4}>
                  <div className="MissionCardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Visão
                    </Typography>
                    <Typography variant="bodyWhite" component="div">
                      <br />
                      Ser reconhecida como uma plataforma de investimentos
                      transparente, confiável e alinhada com seus clientes
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} md={4}>
                  <div className="MissionCardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Valores
                    </Typography>
                    <Typography variant="bodyWhite">
                      <p>Busque 100% de satisfação do cliente.</p>
                      <p>Realize algo digno de orgulho.</p>
                      <p>Seja honesto e inspire confiança.</p>
                      <p>
                        Crie um ambiente positivo e um espírito de família.
                      </p>
                      <p> Cuide do nosso cliente e a Lev cuida de você.</p>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}
