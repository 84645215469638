import { Box } from "@mui/material"
import Footer from "layout/Footer"
import Files from "views/Files"
import "./styles.scss"

export default function Documents() {
  return (
    <Box>
      <Files />
      <Footer />
    </Box>
  )
}
