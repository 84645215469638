import React from "react"
import Landing from "views/Landing"
import Documents from "views/Documents/index"
import { Routes, Route, Navigate } from "react-router-dom"

export default function AllRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/documentos" element={<Documents />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
