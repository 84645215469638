import React from "react"
import Home from "views/Home"
import Mission from "views/Mission"
import WorkStyle from "views/WorkStyle"
import WhoWeAre from "views/WhoWeAre"
import Faq from "views/Faq"
import Products from "views/Products"
import Articles from "views/Articles"
import Fiagro from "views/Fiagro"

import Footer from "layout/Footer"

import "./styles.scss"

export default function Landing() {
  return (
    <div className="Main">
      <Home />
      <Products />
      <WorkStyle />
      <Mission />
      <WhoWeAre />
      <Faq />
      <Articles />
      <Fiagro />
      <Footer />
    </div>
  )
}
