import { CardActionArea } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"

export default function CardArticle({
  image,
  title,
  resource_name,
  date,
  url,
}) {
  const openWindow = (url) => window.open(url)

  return (
    <Card
      sx={{
        maxWidth: 320,
        minHeight: 350,
        margin: "0 0 50px 0",
      }}
    >
      <CardActionArea
        onClick={() => openWindow(url)}
        sx={{
          maxWidth: 320,
          minHeight: 350,
          margin: "0 0 10px 0",
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt={title}
          style={{
            height: 200,
            padding: resource_name === "EXAME INVEST" ? 24 : 0,
            objectFit: resource_name === "EXAME INVEST" ? "contain" : "cover",
          }}
        />
        <CardContent>
          <Typography variant="bodyBlue" component="div" fontSize={18}>
            {title}
          </Typography>
          <br />
          <Typography variant="bodyBlue" component="div" fontSize={12}>
            {resource_name} - {date}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
