import React from "react"
import { Typography, Box, Grid } from "@mui/material"
import TopNavbar from "layout/TopNavbar"
import VerticalLines from "assets/vertical-lines.png"
import "./styles.scss"

export default function Home() {
  return (
    <section id="home">
      <div className="HomeWrapper">
        <TopNavbar />
        <img
          src={VerticalLines}
          alt="VerticalLines"
          className="HomeVerticalLines"
        />
        <Box className="HomeLeftSide">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item className="HomeTitle">
              <Typography sx={{ typography: { md: "h4", xs: "h5" } }}>
                Distribuindo valores com independência, agilidade e
                <div style={{ color: "#ffb335" }}>transparência</div>
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body" fontSize={30}>
                Quando cada milissegundo conta, nosso cliente sai na frente.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
    </section>
  )
}
