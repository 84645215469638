import React from "react"
import { Typography, Box, Grid } from "@mui/material"
import stocks from "assets/stocks.png"
import moneyBag from "assets/money-icon.png"
import VerticalLines from "assets/vertical-lines.png"

import "./styles.scss"

export default function Products() {
  return (
    <>
      <section id="products">
        <Box className="ProductsBackground">
          <img
            src={VerticalLines}
            alt="VerticalLines"
            className="ProductsVerticalLines"
          />
          <div className="ProductsTitle">
            <div className="ProductsTitleText">Produtos e serviços</div>
            <div className="ProductsTitleLine" />
          </div>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={0} md={5}>
              <img className="Stocks" src={stocks} alt="Stocks" />
            </Grid>

            <Grid item xs={10} md={7} className="BodyBackground">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item md={3}>
                  <img className="MoneyBag" src={moneyBag} alt="project" />
                </Grid>

                <Grid item md={3} className="BodyTitle">
                  <Typography variant="BodyTitle">
                    Concebida sobre 3 pilares
                  </Typography>
                  <br />
                  <Typography variant="BodyTitle2">
                    <p>• Excelência na prestação de serviços.</p>
                    <p>• Resiliência operacional.</p>
                    <p>
                      • Alinhamento de interesses com seus clientes,
                      colaboradores e parceiros.
                    </p>
                  </Typography>
                </Grid>

                <Grid item md={3} style={{ marginTop: "4vh" }}>
                  <Typography variant="BodySubTitle">
                    Negociação Eletrônica
                  </Typography>
                  <Typography variant="bodyBlue" component="div" color="gray">
                    Foco em automação para melhor execução de ordens através de
                    algoritmos que aumentam eficiência operacional e diminuem
                    riscos de execução.
                  </Typography>
                </Grid>

                <Grid item md={3} style={{ marginTop: "2.5vh" }}>
                  <Typography variant="BodySubTitle">
                    Intermediação de Títulos e Valores Mobiliários
                  </Typography>
                  <Typography variant="bodyBlue" component="div" color="gray">
                    Oferecemos serviços de intermediação de títulos e valores
                    mobiliários dos produtos listados na B3 e soluções
                    customizadas de backoffice.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}
