import { Grid, IconButton } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import logoLevWhite from "assets/Lev-White.png"
import Backdrop from "components/Elements/Backdrop"
import Sidebar from "layout/SideBar"
import { useState } from "react"
import "./styles.scss"

export default function TopNavbar() {
  const [sidebarOpen, toggleSidebar] = useState(false)

  return (
    <>
      <div className="TopNavbarBackground">
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={9} sm={11} md={11}>
            <img
              src={logoLevWhite}
              alt="logoLevWhite"
              className="TopNavbarLogo"
            />
          </Grid>

          <Grid item xs={3} sm={1} md={1}>
            <IconButton
              color="primary"
              component="label"
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
