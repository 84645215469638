import { Box, Grid, Typography } from "@mui/material"
import "./styles.scss"

export default function WorkStyle() {
  return (
    <>
      <section id="workstyle">
        <Box className="WorkStyleBackground">
          <div className="WorkStyleTitle">
            <div className="WorkStyleTitleText">Como Atuamos</div>
            <div className="WorkStyleTitleLine" />
          </div>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            className="GridWrapper"
          >
            <Grid item xs={10} md={8} className="GridBoxBackground">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={10} md={5}>
                  <div className="CardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Eficiência <br />
                      em processos
                    </Typography>

                    <Typography variant="bodyWhite" style={{ marginTop: 10 }}>
                      Cada processo foi desenhado visando a interação eficiente
                      com uma abordagem tecnológica automatizada.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={10} md={5}>
                  <div className="CardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Segurança <br />
                      acima de tudo
                    </Typography>
                    <Typography variant="bodyWhite" style={{ marginTop: 10 }}>
                      Quando se atua no mercado financeiro, segurança e
                      confidencialidade é prioridade.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={10} md={5}>
                  <div className="CardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Inovação com
                      <br /> experiência
                    </Typography>
                    <Typography variant="bodyWhite" style={{ marginTop: 10 }}>
                      Com profissionais que acumulam décadas de experiência no
                      mercado, a Lev se orgulha de estar na vanguarda das
                      tendências disruptivas do mercado financeiro.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={10} md={5}>
                  <div className="CardWrapper">
                    <Typography
                      variant="BodyTitle2"
                      component="div"
                      align="center"
                    >
                      Atendimento <br />
                      personalizado
                    </Typography>
                    <Typography variant="bodyWhite" style={{ marginTop: 10 }}>
                      Com o objetivo de propor o melhor atendimento, cada
                      cliente é único e deve ser atendido conforme suas
                      demandas e necessidades.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}
