import { Box, Grid } from "@mui/material"
import daiane from "assets/photos/daiane.jpg"
import elio from "assets/photos/elio.jpg"
import fabio from "assets/photos/fabio.jpg"
import marcelo from "assets/photos/marcelo.jpg"
import PeopleBox from "components/PeopleBox"

import "./styles.scss"

export default function WhoWeAre() {
  return (
    <>
      <section id="whoweare">
        <Box className="WhoWeAreBackground">
          <div className="WhoWeAreTitle">
            <div className="WhoWeAreTitleText">Quem Somos</div>
            <div className="WhoWeAreTitleLine"></div>
          </div>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            style={{ paddingBottom: "10vh" }}
          >
            <Grid item xs={0} md={2}></Grid>
            <Grid item xs={10} md={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={8}
              >
                <Grid item xs={9} md={6}>
                  <PeopleBox
                    img={marcelo}
                    title="CEO"
                    subtitle="Marcelo Cerize"
                    text="Advogado, MBA em Direito Comercial, 20 anos de experiência como analista e trader de renda variável na Skopos e CEO da Inversa Publicações."
                    action={() =>
                      window.open(
                        "https://www.linkedin.com/in/marcelo-cerize-80aa1029/"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={9} md={6}>
                  <PeopleBox
                    img={daiane}
                    title="CCO"
                    subtitle="Daiane Pereira"
                    text="Economista, especialização em mercado financeiro, MBA em Compliance, 10 anos de atuação na Anbima, gerente sênior compliance na Safra Asset."
                    action={() =>
                      window.open(
                        "https://www.linkedin.com/in/daiane-pereira-68803270/"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={9} md={6}>
                  <PeopleBox
                    img={elio}
                    title="CTO"
                    subtitle="Elio Makuda"
                    text="Mais de 25 anos atuando em diversas áreas de sistemas e infraestrutura, sendo mais de 10 anos no mercado financeiro."
                    action={() =>
                      window.open(
                        "https://www.linkedin.com/in/elio-makuda-6434206/"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={9} md={6}>
                  <PeopleBox
                    img={fabio}
                    title="COO"
                    subtitle="Fábio Oliveira"
                    text="Profissional de Operações, atuando desde 1983 no mercado financeiro brasileiro, sendo responsável pela equipe de middle e back-office da Link/UBS."
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}
