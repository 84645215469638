import { Box } from "@mui/material"
import StepperArticles from "components/StepperArticles"
import "./styles.scss"

export default function Articles() {
  return (
    <>
      <section id="articles">
        <Box className="ArticlesBackground">
          <div className="ArticlesTitle">
            <div className="ArticlesTitleText">Artigos</div>
            <div className="ArticlesTitleLine" />
          </div>
          <Box m={4}>
            <StepperArticles />
          </Box>
        </Box>
      </section>
    </>
  )
}
