import * as React from "react"
import { Button, Box, DialogTitle, Dialog, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

function SimpleDialog({ onClose, open }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle sx={{ fontSize: "14px" }}>
        Canal e Identificação do DPO
      </DialogTitle>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Box m={3}>
        A LGPD prevê que todas as empresas tenham um Encarregado pelo
        Tratamento de Dados Pessoais, conforme é mencionado no parágrafo 1º do
        artigo 41 da Lei n.º 13.709/2018. <br />
        <br /> A Lev DTVM nomeou a HMD Consultoria e Gestão Empresarial Ltda. como DPO (ou Encarregado
        de Proteção de Dados) que pode ser contatado a qualquer momento pelo
        e-mail:{" "}
        <a style={{ color: "darkblue" }} href="mailto:dpo@lev.com.vc">
          dpo@lev.com.vc
        </a>
        .
      </Box>
    </Dialog>
  )
}

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{ padding: 0, marginTop: "10px", textAlign: "left" }}
      >
        <b>Canal e Identificação do DPO</b>
      </Button>
      <SimpleDialog open={open} onClose={handleClose} />
    </div>
  )
}
