import React from "react"
import { Link } from "react-scroll"
// Assets
import CloseIcon from "../../assets/svg/CloseIcon"
import logoLev from "assets/Lev-White.png"
import "./styles.scss"

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <nav
      className="SideBarWrapper animate darkBg"
      sidebaropen={sidebarOpen.toString()}
      style={{ right: sidebarOpen ? 0 : -400 }}
    >
      <div className="SidebarHeader flexSpaceCenter">
        <div className="flexNullCenter">
          <img
            src={logoLev}
            alt="fireSpot"
            style={{ width: "120px", paddingTop: 5 }}
          />
        </div>
        <button
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="SideBarCloseBtn animate pointer"
        >
          <CloseIcon />
        </button>
      </div>

      <ul className="SideBarUlStyle flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
          >
            Início
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="products"
            spy={true}
            smooth={true}
          >
            Produtos e Serviços
          </Link>
        </li>

        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="workstyle"
            spy={true}
            smooth={true}
          >
            Como Atuamos
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="mission"
            spy={true}
            smooth={true}
          >
            Missão, Visão e Valores
          </Link>
        </li>

        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="whoweare"
            spy={true}
            smooth={true}
          >
            Quem Somos
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="faq"
            spy={true}
            smooth={true}
          >
            FAQ
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="articles"
            spy={true}
            smooth={true}
          >
            Artigos
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="fiagro"
            spy={true}
            smooth={true}
          >
            Investimentos - Oferta Pública
          </Link>
        </li>
      </ul>
    </nav>
  )
}
